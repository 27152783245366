@import '~#/theme/main';

.img-fallback {
  background-color: rgba(91, 91, 91, .55);
  background-position: center;
  background-repeat: no-repeat;
}

.img-container {
  border-radius: vw(16px);
  height: 100%;
  width: 100%;

  .img-content {
    height: 100%;
    transition: opacity .5s;
    width: 100%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .img-loading {
    opacity: 0;
  }

  .img-loaded {
    opacity: 1;
  }
}
