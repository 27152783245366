@import '~#/theme/main';

.item {
  button {

    @include default-button;

    @include subtitle-font-size;

    align-items: center;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: rgba(242, 243, 244, .8);
    cursor: pointer;
    display: flex;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.primary {
  button {
    padding: vw(18px) vw(36px);
  }
}

.secondary {
  button {
    padding: 0 vw(20px);
  }
}

.icon {
  height: fit-content;

  svg {
    height: vw(45px);

    path {
      fill: var(--coloronbackground);
    }
  }
}

.selected {
  .default {
    button {
      background: rgba(242, 243, 244, .2);
      border-radius: vw(35px);
      color: var(--coloronaccent);
    }
  }
}

.focused {
  .default {
    button {
      background-color: var(--accent);
      border-radius: vw(35px);
      color: var(--coloronaccent);
    }
  }

  .icon {
    svg {
      path {
        fill: var(--accent);
      }
    }
  }
}
