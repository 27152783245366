@import '~#/theme/vw';

@import '~#/theme/main';

.container {
  position: absolute;

  .img-container {
    border: vw(3px) solid transparent;

    &.img-container-focused {
      border: vw(3px) solid var(--accent);
      border-radius: vw(16.8px);
    }

    .card-img {
      border-radius: vw(13.571px);
    }

    .progress {
      background-color: rgba($light-grey, .5);
      bottom: vw(87px);
      height: vw(12px);
      overflow: hidden;
      position: relative;
      width: 100%;
      z-index: 1;

      > span {
        background-color: $white-inner-bar;
        display: block;
        height: 100%;
      }
    }

    .title {
      background-color: $grey-55;
      border-radius: 0 0 vw(13.571px) vw(13.571px);
      bottom: vw(87px);
      font-size: vw(24px);
      font-style: normal;
      font-weight: 500;
      height: vw(44px);
      line-height: vw(44px);
      overflow: hidden;
      padding: vw(16px);
      position: relative;
      text-overflow: ellipsis;
      text-shadow: $text-shadow;
      white-space: nowrap;
      width: 94.5%;

      &.title-focused {
        background-color: var(--accent);
        right: vw(1px);
        width: 94.8%;
      }
    }
  }
}
