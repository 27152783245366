@import '~#/theme/main';

.wrapper {
  background-color: $rgb-60;
  border-radius: vw(15px);
  box-sizing: border-box;

  &.focused {
    background-color: var(--accent);
  }

  .title {
    color: var(--coloronbackground);
    font-size: vw(30px);
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
}
