@import '~#/theme/main';

$details-text-color: $rgb-235;

.container {
  box-sizing: border-box;
  display: inline-block;

  .link {
    height: 100%;
    position: relative;
    width: 100%;

    .background {
      background-repeat: no-repeat;
      background-size: cover;
      border: vw(3px) solid transparent;
      border-radius: vw(15px);
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;

      @include browser($legacy-devices) {
        border-radius: 12px !important;
      }

      &.background-focused {
        border: vw(3px) solid var(--accent);
      }

      &.background-focused .click-area {
        position: absolute;
        top: 0;
      }

      .view-all {
        font-size: 26px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 35%;
        width: 100%;
      }

      &.view-all-background {
        background-color: #303030;
        border-radius: vw(19px);
      }

      .logo-image {
        object-fit: contain;
      }

      .content-container {
        background-color: $grey-55;
        border-radius: 0 0 vw(15px) vw(15px);
        bottom: vw(2px);
        color: var(--coloronbackground);
        height: auto;
        left: vw(2px);
        min-height: vw(32px);
        position: absolute;

        .title {
          box-sizing: border-box;
          font-size: vw(23px);
          font-weight: 700;
          height: vw(75px);
          line-height: vw(32px);
          min-height: vw(32px);
          overflow: hidden;
          padding: vw(22px);
          text-overflow: ellipsis;
          text-shadow: $text-shadow;
          white-space: nowrap;
          width: 100%;

          &.title-continue-watching {
            padding: vw(27px);
          }
        }
      }

      .content-container-focused {

        @extend .content-container;

        background-color: var(--accent);
        bottom: 0;
      }
    }
  }
}
