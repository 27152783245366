.container {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;

  &.hide {
    display: none;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &::after {
    background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
