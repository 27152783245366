@import '~#/theme/main';

.background {

  @include bottom-align-container;

  background-repeat: no-repeat;
  background-size: cover;
  height: inherit;

  img {
    min-width: 100vw;
    object-fit: cover;
  }
}

.content-container {

  @include bottom-align-item;

  background:
    linear-gradient(
      330deg,
      rgba(9, 13, 17, 0) 49.15%,
      rgba(9, 13, 17, .4) 58.26%,
      rgba(9, 13, 17, .7) 73.03%,
      rgba(9, 13, 17, .85) 99.69%
    ),
    linear-gradient(
      360deg,
      rgba(0, 0, 0, 1) .85%,
      rgba(9, 13, 17, .4) 33.21%,
      rgba(9, 13, 17, .1) 49%,
      rgba(9, 13, 17, .1) 86.44%
    );
  box-sizing: border-box;
  height: 100%;
  padding: vw(270px) vw(88px) vw(77px);
  width: 100%;

  .button {
    font-size: vw(30px);
    height: vw(35px);
    left: 5vw;
    line-height: normal;
    top: 20vh;
  }
}

.title {
  color: $font-color;
  font-size: vw(50px);
  height: vw(79px);
  line-height: normal;
  margin-top: vw(50px);
  padding: vw(30px) 0;
  text-shadow: $text-shadow;
}
